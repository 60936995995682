import styled from 'styled-components/macro';

const Footer = styled.div`
  margin-top: 32px;
  height: 72px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin-top: 48px;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 11px;
  line-height: 13px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const FooterImage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin-top: 24px;
  }
`;

interface MpFooterProps {
  showTermsOfService?: boolean;
}

export const MpFooter = ({ showTermsOfService }: MpFooterProps) => {
  return (
    <Footer>
      <FooterLinks>
        {showTermsOfService ? (
          <a
            href="/Legal.aspx"
            target="_blank"
            rel="noopener noreferrer"
            id="termsOfUse"
          >
            Terms of Use
          </a>
        ) : null}
      </FooterLinks>
      <FooterImage>Powered by NCR VOYIX</FooterImage>
    </Footer>
  );
};
